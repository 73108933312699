/* eslint-disable no-unused-vars */
import { StateCreator } from 'zustand';
import { Project } from '../../models/projects/project';
import { Team } from '../../models/teams/teams';
import {
  createTeamAction,
  createTeamDto,
  fetchTeamsAction,
  removeTeamAction,
  updateTeamAction,
} from '../../repositories/team_repo';

export interface TeamsSlice {
  teams: Team[];

  //crud
  createTeamEvent: (details: createTeamDto) => void;
  failureCreatingTeam: string;
  isCreatingTeam: boolean;
  successfullyCreatedTeam: boolean;

  updateTeamEvent: (teamID: string, details: Partial<createTeamDto>) => void;
  failureUpdatingTeam: string;
  isUpdatingTeam: boolean;
  successfullyUpdatedTeam: boolean;

  removeTeamEvent: (teamID: string) => void;
  teamBeingRemoved: string;
  failureRemovingTeam: string;
  isRemovingTeam: boolean;
  successfullyRemovedTeam: boolean;

  //fetch teams
  fetchTeamsEvent: () => void;
  failureFetchingTeams: string;
  isFetchingTeams: boolean;

  //resetTeam
  resetTeamFailureEvent: () => void;

  currentTeamProject: Project | undefined;

  //
  isTeamEditMode: boolean;
  teamBeingEdited?: Team;

  turnOnTeamEditModeEvent: (team: Team) => void;
  turnOffTeamEditModeEvent: () => void;
}

export const createTeamSlice: StateCreator<TeamsSlice, [], [], TeamsSlice> = (
  set,
  get
) => ({
  teams: [],
  failureFetchingTeams: '',
  isFetchingTeams: false,

  failureCreatingTeam: '',
  isCreatingTeam: false,
  successfullyCreatedTeam: false,

  failureUpdatingTeam: '',
  isUpdatingTeam: false,
  successfullyUpdatedTeam: false,

  teamBeingRemoved: '',
  failureRemovingTeam: '',
  isRemovingTeam: false,
  successfullyRemovedTeam: false,

  isTeamEditMode: false,
  teamBeingEdited: undefined,

  currentTeamProject: undefined,

  resetTeamFailureEvent: () =>
    set({
      failureCreatingTeam: '',
      failureFetchingTeams: '',
      failureRemovingTeam: '',
      failureUpdatingTeam: '',
    }),

  //events
  createTeamEvent: async (details: createTeamDto) => {
    set({ isCreatingTeam: true });
    const res = await createTeamAction(details);

    if (typeof res === 'string') {
      set({ isCreatingTeam: false, failureCreatingTeam: res });
      return;
    }

    set({
      isCreatingTeam: false,
      failureCreatingTeam: '',
      successfullyCreatedTeam: true,
    });

    get().fetchTeamsEvent();

    setTimeout(() => {
      set({
        successfullyCreatedTeam: false,
      });
    }, 300);
  },
  updateTeamEvent: async (teamID: string, details: Partial<createTeamDto>) => {
    set({ isUpdatingTeam: true });

    const res = await updateTeamAction(teamID, details);

    if (typeof res === 'string') {
      set({ isUpdatingTeam: false, failureUpdatingTeam: res });
      return;
    }

    set({
      isUpdatingTeam: false,
      failureUpdatingTeam: '',
      successfullyUpdatedTeam: true,
    });

    get().fetchTeamsEvent();

    setTimeout(() => {
      set({
        successfullyUpdatedTeam: false,
      });
    }, 300);
  },

  removeTeamEvent: async (teamID: string) => {
    set({ isRemovingTeam: true, teamBeingRemoved: teamID });

    const res = await removeTeamAction(teamID);

    if (typeof res === 'string') {
      set({
        isRemovingTeam: false,
        failureRemovingTeam: res,
        teamBeingRemoved: '',
      });
      return;
    }

    set({
      isRemovingTeam: false,
      failureRemovingTeam: '',
      successfullyRemovedTeam: true,
      teamBeingRemoved: '',
    });

    get().fetchTeamsEvent();

    setTimeout(() => {
      set({
        successfullyRemovedTeam: false,
      });
    }, 300);
  },

  fetchTeamsEvent: async () => {
    set({ isFetchingTeams: true });

    const res = await fetchTeamsAction();

    if (typeof res === 'string') {
      set({ isFetchingTeams: false, failureFetchingTeams: res });
      return;
    }

    set({
      isFetchingTeams: false,
      failureFetchingTeams: '',
      teams: res,
    });
  },
  turnOnTeamEditModeEvent: (team: Team) =>
    set({
      isTeamEditMode: true,
      teamBeingEdited: team,
    }),
  turnOffTeamEditModeEvent: () =>
    set({
      isTeamEditMode: false,
      teamBeingEdited: undefined,
    }),
});
