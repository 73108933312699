import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { db } from '../common/configs/shiftswap-firebase';
import { Team } from '../models/teams/teams';
import { handleError } from '../utils/handle_errors';

export type createTeamDto = {
  id: string;
  name: string;
  description: string;
  createdAt: number;
  updatedAt: number;
  projectID?: string;
};

const fetchTeamsAction = async (): Promise<Team[] | string> => {
  try {
    const teamsCol = collection(db, 'teams');

    const snapshots = await getDocs(
      query(teamsCol, orderBy('createdAt', 'desc'))
    );

    const teams = snapshots.docs.map((team) => team.data() as Team);

    return teams;
  } catch (error) {
    return handleError(error);
  }
};

const createTeamAction = async (
  details: createTeamDto
): Promise<Team | string> => {
  try {
    await setDoc(doc(db, 'teams', details.id), details);

    return details as Team;
  } catch (error) {
    return handleError(error);
  }
};

const removeTeamAction = async (details: string): Promise<Team | string> => {
  try {
    await deleteDoc(doc(db, 'teams', details));

    return {} as Team;
  } catch (error) {
    return handleError(error);
  }
};

const updateTeamAction = async (
  projectId: string,
  details: Partial<createTeamDto>
): Promise<Team | string> => {
  try {
    await setDoc(doc(db, 'teams', projectId), details, {
      merge: true,
    });

    return details as Team;
  } catch (error) {
    return handleError(error);
  }
};

export {
  fetchTeamsAction,
  createTeamAction,
  removeTeamAction,
  updateTeamAction,
};
