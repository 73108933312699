import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../common/configs/shiftswap-firebase';
import { Role } from '../common/constants/enum';
import { Team } from '../models/teams/teams';
import { User } from '../models/users/users';
import { handleError } from '../utils/handle_errors';
import { createUserAction, updateUserAction } from './user_repo';

export type addNewEmployeeDto = {
  email: string;
  password: string;
  role: Role;
  createdAt: number;
  firstName: string;
  id: string;
  isActive: boolean;
  lastName: string;
  phone: string;
  teamId: number | string | undefined;
  updatedAt: number;
};

const deactivateEmployeeAction = async (
  userID: string
): Promise<{} | string> => {
  try {
    await updateUserAction(userID, {
      isActive: false,
    });

    return {};
  } catch (error) {
    return handleError(error);
  }
};

const activateEmployeeAction = async (userID: string): Promise<{} | string> => {
  try {
    await updateUserAction(userID, {
      isActive: true,
    });

    return {};
  } catch (error) {
    return handleError(error);
  }
};

const fetchAllEmployeesAction = async (): Promise<User[] | string> => {
  try {
    const usersCol = collection(db, 'users');

    const snapshots = await getDocs(
      query(
        usersCol,
        where('role', '==', Role.employee),
        orderBy('createdAt', 'desc')
      )
    );

    const users = snapshots.docs.map((user) => user.data() as User);

    return users;
  } catch (error) {
    return handleError(error);
  }
};

const fetchActivatedEmployeesAction = async (): Promise<User[] | string> => {
  try {
    const usersCol = collection(db, 'users');

    const snapshots = await getDocs(
      query(
        usersCol,
        where('role', '==', Role.employee),
        where('isActive', '==', true),
        orderBy('createdAt', 'desc')
      )
    );

    const users = snapshots.docs.map((user) => user.data() as User);

    return users;
  } catch (error) {
    return handleError(error);
  }
};

const fetchDeactivatedEmployeesAction = async (): Promise<User[] | string> => {
  try {
    const usersCol = collection(db, 'users');

    const snapshots = await getDocs(
      query(
        usersCol,
        where('role', '==', Role.employee),
        where('isActive', '==', false),
        orderBy('createdAt', 'desc')
      )
    );

    const users = snapshots.docs.map((user) => user.data() as User);

    return users;
  } catch (error) {
    return handleError(error);
  }
};

const fetchAllEmployeesUnderAProjectAction = async (
  projectID: string
): Promise<User[] | string> => {
  try {
    const teamsCol = collection(db, 'teams');

    const snapshots = await getDocs(
      query(
        teamsCol,
        where('projectID', '==', projectID),
        orderBy('createdAt', 'desc')
      )
    );

    const teams = snapshots.docs
      .map((team) => team.data() as Team)
      .map((data) => data.id);

    if (teams.length === 0) {
      return [] as User[];
    }

    const usersCol = collection(db, 'users');

    const userSnapshots = await getDocs(
      query(
        usersCol,
        where('teamId', 'in', teams),
        orderBy('createdAt', 'desc')
      )
    );

    const users = userSnapshots.docs.map((user) => user.data() as User);

    return users;
  } catch (error) {
    return handleError(error);
  }
};

const fetchAllEmployeesUnderATeamAction = async (
  teamID: string
): Promise<User[] | string> => {
  try {
    const usersCol = collection(db, 'users');

    const userSnapshots = await getDocs(
      query(
        usersCol,
        where('teamId', '==', teamID),
        orderBy('createdAt', 'desc')
      )
    );

    const users = userSnapshots.docs.map((user) => user.data() as User);

    return users;
  } catch (error) {
    return handleError(error);
  }
};

const fetchAllEmployeesForAGivenProjectAndTeamAction = async (
  teamID: string,
  projectID: string
): Promise<User[] | string> => {
  try {
    const teamsCol = collection(db, 'teams');

    const snapshots = await getDocs(
      query(
        teamsCol,
        where('projectID', '==', projectID),
        orderBy('createdAt', 'desc')
      )
    );

    const teams = snapshots.docs
      .map((team) => team.data() as Team)
      .map((data) => data.id);

    if (!teams.includes(teamID)) {
      return [] as User[];
    }

    const usersCol = collection(db, 'users');

    const userSnapshots = await getDocs(
      query(
        usersCol,
        where('teamId', '==', teamID),
        orderBy('createdAt', 'desc')
      )
    );

    const users = userSnapshots.docs.map((user) => user.data() as User);

    return users;
  } catch (error) {
    return handleError(error);
  }
};

const addNewEmployeeAction = async (
  details: addNewEmployeeDto
): Promise<User | string> => {
  try {
    const res = await createUserAction(details);

    return res;
  } catch (error) {
    return handleError(error);
  }
};

export {
  fetchAllEmployeesAction,
  fetchActivatedEmployeesAction,
  fetchDeactivatedEmployeesAction,
  addNewEmployeeAction,
  fetchAllEmployeesUnderAProjectAction,
  fetchAllEmployeesUnderATeamAction,
  fetchAllEmployeesForAGivenProjectAndTeamAction,
  deactivateEmployeeAction,
  activateEmployeeAction,
};
