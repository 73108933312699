/* eslint-disable no-unused-vars */
export enum AuthenticationStatus {
  unknown = 'unkonwn',
  authenticated = 'authenticated',
  unauthenticated = 'unauthenticated',
}

export enum ToastType {
  success = 'success',
  warning = 'warning',
}

export enum FilterEmployeeListByStatus {
  All = 'All',
  Activated = 'Activated',
  Deactivated = 'Deactivated',
}

export enum MessageAccessLevel {
  siteWide = 'siteWide',
  projectWide = 'projectWide',
  teamWide = 'teamWide',
}

export enum Role {
  superAdmin = 'super-admin',
  admin = 'admin',
  employee = 'employee',
}

export enum ShiftStatus {
  active = 'active',
  inactive = 'inactive',
}
