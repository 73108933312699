import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { db } from '../common/configs/shiftswap-firebase';
import { Project } from '../models/projects/project';
import { handleError } from '../utils/handle_errors';

export type createProjectDto = {
  id: string;
  name: string;
  description: string;
  createdAt: number;
  updatedAt: number;
  createdByUserID: string;
};

const fetchProjectsAction = async (): Promise<Project[] | string> => {
  try {
    const projectsCol = collection(db, 'projects');

    const snapshots = await getDocs(
      query(projectsCol, orderBy('createdAt', 'desc'))
    );

    const projects = snapshots.docs.map(
      (projects) => projects.data() as Project
    );

    return projects;
  } catch (error) {
    return handleError(error);
  }
};

const createProjectAction = async (
  details: createProjectDto
): Promise<Project | string> => {
  try {
    await setDoc(doc(db, 'projects', details.id), details);

    return details as Project;
  } catch (error) {
    return handleError(error);
  }
};

const removeProjectAction = async (
  details: string
): Promise<Project | string> => {
  try {
    await deleteDoc(doc(db, 'projects', details));

    return {} as Project;
  } catch (error) {
    return handleError(error);
  }
};

const updateProjectAction = async (
  projectId: string,
  details: Partial<createProjectDto>
): Promise<Project | string> => {
  try {
    await setDoc(doc(db, 'projects', projectId), details, {
      merge: true,
    });

    return details as Project;
  } catch (error) {
    return handleError(error);
  }
};

export {
  fetchProjectsAction,
  createProjectAction,
  removeProjectAction,
  updateProjectAction,
};
