import {
  faBoxArchive,
  faBuildingUser,
  faCalendar,
  faComments,
  faLeftRight,
  faNoteSticky,
  faUsers,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { ToastType } from '../common/constants/enum';
import {
  employeesRoute,
  greetingsRoute,
  projectsRoute,
  shiftManagerRoute,
  tasksRoute,
  teamsRoute,
  todayRoute,
} from '../routes/routes';

export type menuItemDefiniton = {
  title: string;
  icon: IconDefinition;
  route: string;
};

export const menuList: menuItemDefiniton[] = [
  {
    title: 'Employees',
    route: employeesRoute,
    icon: faUsers,
  },
  {
    title: 'Greetings',
    route: greetingsRoute,
    icon: faComments,
  },
  {
    title: 'Shift Scheduler',
    route: shiftManagerRoute,
    icon: faLeftRight,
  },
  {
    title: 'Today',
    route: todayRoute,
    icon: faCalendar,
  },
  {
    title: 'Tasks',
    route: tasksRoute,
    icon: faNoteSticky,
  },
  {
    title: 'Teams',
    route: teamsRoute,
    icon: faBuildingUser,
  },
  {
    title: 'Projects',
    route: projectsRoute,
    icon: faBoxArchive,
  },
];

export const notifySnackbar = (message: string, type: ToastType) => {
  if (type === ToastType.warning) {
    return toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const getDates = (startDate: Date, endDate: Date): Date[] => {
  const dates = [];
  let currentDate = startDate;

  const addDays = function (days: number) {
    const date = new Date(currentDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
};

export function getMonth(month = dayjs().month()) {
  month = Math.floor(month);
  const year = dayjs().year();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });
  return daysMatrix;
}

export const timeOfDay = [
  {
    '12hr': '12 AM',
    '24hr': '0',
  },
  {
    '12hr': '1 AM',
    '24hr': '1',
  },
  {
    '12hr': '2 AM',
    '24hr': '2',
  },
  {
    '12hr': '3 AM',
    '24hr': '3',
  },
  {
    '12hr': '4 AM',
    '24hr': '4',
  },
  {
    '12hr': '5 AM',
    '24hr': '5',
  },
  {
    '12hr': '6 AM',
    '24hr': '6',
  },
  {
    '12hr': '7 AM',
    '24hr': '7',
  },
  {
    '12hr': '8 AM',
    '24hr': '8',
  },
  {
    '12hr': '9 AM',
    '24hr': '9',
  },
  {
    '12hr': '10 AM',
    '24hr': '10',
  },
  {
    '12hr': '11 AM',
    '24hr': '11',
  },
  {
    '12hr': '12 PM',
    '24hr': '12',
  },
  {
    '12hr': '1 PM',
    '24hr': '13',
  },
  {
    '12hr': '2 PM',
    '24hr': '14',
  },
  {
    '12hr': '3 PM',
    '24hr': '15',
  },
  {
    '12hr': '4 PM',
    '24hr': '16',
  },
  {
    '12hr': '5 PM',
    '24hr': '17',
  },
  {
    '12hr': '6 PM',
    '24hr': '18',
  },
  {
    '12hr': '7 PM',
    '24hr': '19',
  },
  {
    '12hr': '8 PM',
    '24hr': '20',
  },
  {
    '12hr': '9 PM',
    '24hr': '21',
  },
  {
    '12hr': '10 PM',
    '24hr': '22',
  },
  {
    '12hr': '11 PM',
    '24hr': '23',
  },
];
