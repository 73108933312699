import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { db } from '../common/configs/shiftswap-firebase';
import { MessageAccessLevel } from '../common/constants/enum';
import { Message } from '../models/messages/message';
import { handleError } from '../utils/handle_errors';

export type createMessageDto = {
  id: string;
  accessLevel: MessageAccessLevel;
  subject: string;
  message: string;
  projectID: string;
  teamID: string;
  createdAt: number;
  createdByUserId: string;
  status: string;
};

const fetchMessagesAction = async (): Promise<Message[] | string> => {
  try {
    const messagesCol = collection(db, 'messages');

    const snapshots = await getDocs(
      query(messagesCol, orderBy('createdAt', 'desc'))
    );

    const messages = snapshots.docs.map((message) => message.data() as Message);

    return messages;
  } catch (error) {
    return handleError(error);
  }
};

const createMessageAction = async (
  details: createMessageDto
): Promise<Message | string> => {
  try {
    await setDoc(doc(db, 'messages', details.id), details);

    return details as Message;
  } catch (error) {
    return handleError(error);
  }
};

const deleteMessageAction = async (messageID: string): Promise<{} | string> => {
  try {
    await deleteDoc(doc(db, 'messages', messageID));

    return {} as Message;
  } catch (error) {
    return handleError(error);
  }
};

const updateMessageAction = async (
  messageID: string,
  subject: string,
  message: string,
  accessLevel: MessageAccessLevel,
  projectID?: string,
  teamID?: string
): Promise<{} | string> => {
  try {
    await setDoc(
      doc(db, 'messages', messageID),
      {
        subject,
        message,
        accessLevel,
        projectID,
        teamID,
      },
      {
        merge: true,
      }
    );

    return {};
  } catch (error) {
    return handleError(error);
  }
};

export {
  fetchMessagesAction,
  createMessageAction,
  deleteMessageAction,
  updateMessageAction,
};
