/* eslint-disable no-unused-vars */
import { StateCreator } from 'zustand';
import { AuthenticationStatus } from '../../common/constants/enum';
import { User } from '../../models/users/users';
import {
  loginUserAction,
  loginUserDto,
  logoutAction,
  resetPasswordAction,
  resetPasswordDto,
} from '../../repositories/user_repo';

export interface AuthSlice {
  //authstate
  authentication: AuthenticationStatus;

  //login state
  failureLogin: string;
  isSubmittingLogin: boolean;
  user: User;

  //reset password state
  failureSendEmailReset: string;
  isSubmittingSendEmailReset: boolean;
  successfulSentResetEmail: boolean;

  //set newpassword state
  failureSetNewPassword: string;
  isSubmittingSetNewPassword: boolean;
  successfulSetNewPassword: boolean;

  //actions
  loginEvent: (details: loginUserDto) => void;
  logoutEvent: () => void;
  resetFailureEvent: () => void;
  resetPasswordEvent: (details: resetPasswordDto) => void;
}

export const createAuthSlice: StateCreator<AuthSlice, [], [], AuthSlice> = (
  set
) => ({
  //auth state
  authentication: AuthenticationStatus.unknown,

  //login state
  failureLogin: '',
  isSubmittingLogin: false,
  user: {
    createdAt: undefined,
    email: '',
    firstName: '',
    id: '',
    isActive: false,
    lastName: '',
    phone: '',
    role: '',
    teamId: undefined,
    token: '',
    updatedAt: undefined,
  },

  // -- reset password --
  failureSendEmailReset: '',
  isSubmittingSendEmailReset: false,
  successfulSentResetEmail: false,

  // -- set new password --
  failureSetNewPassword: '',
  isSubmittingSetNewPassword: false,
  successfulSetNewPassword: false,

  //actions
  loginEvent: async (details: loginUserDto) => {
    set({ isSubmittingLogin: true, failureLogin: '' });
    const res = await loginUserAction(details);

    if (typeof res === 'string') {
      set({ isSubmittingLogin: false, failureLogin: res });
      return;
    }

    set({
      isSubmittingLogin: false,
      authentication: AuthenticationStatus.authenticated,
      user: res,
    });
  },
  logoutEvent: () => {
    logoutAction();
    set((_) => ({ authentication: AuthenticationStatus.unauthenticated }));
  },
  resetFailureEvent: () =>
    set((_) => ({
      failureLogin: '',
      failureSendEmailReset: '',
      failureSetNewPassword: '',
    })),
  resetPasswordEvent: async (details: resetPasswordDto) => {
    set({ isSubmittingSendEmailReset: true, failureSendEmailReset: '' });

    const res = await resetPasswordAction(details);

    if (typeof res === 'string') {
      set({ isSubmittingSendEmailReset: false, failureSendEmailReset: res });
      return;
    }

    set({
      isSubmittingSendEmailReset: false,
      successfulSentResetEmail: true,
    });

    setTimeout(() => {
      set({
        successfulSentResetEmail: false,
      });
    }, 500);
  },
});
