import { AxiosError } from 'axios';

export const handleError = (error: any): string => {
  try {
    if (error instanceof AxiosError) {
      if (error.code === 'ERR_NETWORK') {
        return 'No or poor internet connection. Check your connection and try again.';
      }

      if (error.response?.data.error?.message !== undefined) {
        if (
          error.response?.data.error?.message === 'Invalid email or password'
        ) {
          return 'Wrong combination of email and password provided. Check and try again.';
        }
      }

      if (error.code === 'ERR_BAD_REQUEST') {
        if (error?.response?.data.error.details !== undefined) {
          const path: string = (
            error?.response?.data.error.details[0].path as string
          ).substring(1);
          const message: string =
            error?.response?.data.error.details[0].message;

          return `${path} ${message}`;
        }

        if (error?.response?.data.error.message !== undefined) {
          return error?.response?.data.error.message;
        }
      }

      if (error.code === 'ECONNABORTED' && error?.message.includes('timeout')) {
        return `We couldn't connect with the server kindly try again.`;
      }
    }

    return 'Server failure occurred, kindly try again';
  } catch (_) {
    return 'Server failure occurred, kindly try again';
  }
};

export const handleAuthError = (msg: string): string => {
  let error = 'an unexpected error occurred please try again';

  switch (msg) {
    case 'auth/user-not-found':
      error = 'no user with this email was found';
      break;
    case 'auth/email-already-exists':
      error = 'this email address is already taken';
      break;
    case 'auth/email-already-in-use':
      error = 'this email address is already taken';
      break;
    case 'auth/invalid-email':
      error = 'this email address is invalid';
      break;
    case 'auth/invalid-password':
      error = 'the password supplied is to short minimum 6 character required';
      break;
    case 'auth/wrong-password':
      error = 'the password you supplied is incorrect';
      break;
    case 'auth/uid-already-exists':
      error = 'user with this uid already exists';
      break;
    default:
      break;
  }

  return error;
};
