import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { db } from '../common/configs/shiftswap-firebase';
import { ShiftStatus } from '../common/constants/enum';
import { Shift, ShiftMetas, ShiftTasks } from '../models/shifts/shift';
import { Team } from '../models/teams/teams';
import { User } from '../models/users/users';
import { handleError } from '../utils/handle_errors';

export type createShiftDto = {
  id: string;
  status: ShiftStatus;
  mandatory: boolean;
  startDate: number;
  endDate: number;
  startTime: number;
  endTime: number;
  name: string;
  description: string;
  projectID: string;
  daysHeadsUpBeforeSwap: number;
  createdAt: number;
  updatedAt: number;
  weekDays: string[];
  teamID: string;
  shiftTasks: ShiftTasks[];
  shiftMetas: ShiftMetas[];
  employees: User[];
  label: string;
  location: string;
};

const getTeamsOfAProjectAction = async (
  projectID: string
): Promise<Team[] | string> => {
  try {
    const teamsCol = collection(db, 'teams');

    const snapshots = await getDocs(
      query(
        teamsCol,
        where('projectID', '==', projectID),
        orderBy('createdAt', 'desc')
      )
    );

    return snapshots.docs.map((team) => team.data() as Team);
  } catch (error) {
    console.log(error);
    return handleError(error);
  }
};

const fetchShiftsAction = async (): Promise<Shift[] | string> => {
  try {
    const shiftsCol = collection(db, 'shifts');

    const snapshots = await getDocs(
      query(shiftsCol, orderBy('createdAt', 'desc'))
    );

    const shifts = snapshots.docs.map((task) => task.data() as Shift);

    return shifts;
  } catch (error) {
    return handleError(error);
  }
};

const createShiftAction = async (
  details: createShiftDto
): Promise<Shift | string> => {
  try {
    await setDoc(doc(db, 'shifts', details.id), details);

    return details as Shift;
  } catch (error) {
    console.log(error);
    return handleError(error);
  }
};

const deleteShiftAction = async (shiftID: string): Promise<{} | string> => {
  try {
    await deleteDoc(doc(db, 'shifts', shiftID));

    return {} as Shift;
  } catch (error) {
    return handleError(error);
  }
};

const updateShiftAction = async (
  shiftID: string,
  details: Partial<createShiftDto>
): Promise<{} | string> => {
  try {
    await setDoc(doc(db, 'shifts', shiftID), details, {
      merge: true,
    });

    return details as Shift;
  } catch (error) {
    return handleError(error);
  }
};

export {
  fetchShiftsAction,
  createShiftAction,
  deleteShiftAction,
  updateShiftAction,
  getTeamsOfAProjectAction,
};
