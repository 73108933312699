/* eslint-disable no-unused-vars */
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { StateCreator } from 'zustand';
import { Shift, ShiftMetas, ShiftTasks } from '../../models/shifts/shift';
import { User } from '../../models/users/users';
import { fetchAllEmployeesForAGivenProjectAndTeamAction } from '../../repositories/employee_repo';
import {
  createShiftAction,
  createShiftDto,
  deleteShiftAction,
  fetchShiftsAction,
  updateShiftAction,
} from '../../repositories/shift_repo';
import { getDates } from '../../utils/utils';

export interface ShiftSlice {
  shifts: Shift[];

  //crud
  createShiftEvent: (details: createShiftDto) => void;
  failureCreatingShift: string;
  isCreatingShift: boolean;
  successfullyCreatedShift: boolean;

  updateShiftEvent: (
    shiftID: string,
    details: createShiftDto | Partial<createShiftDto>
  ) => void;
  failureUpdatingShift: string;
  isUpdatingShift: boolean;
  successfullyUpdatedShift: boolean;

  removeShiftEvent: (shiftID: string) => void;
  shiftBeingRemoved: string;
  failureRemovingShift: string;
  isRemovingShift: boolean;
  successfullyRemovedShift: boolean;

  //fetch teams
  fetchShiftsEvent: () => void;
  failureFetchingShifts: string;
  isFetchingShifts: boolean;

  //resetTeam
  resetShiftFailureEvent: () => void;

  //
  isShiftEditMode: boolean;
  shiftBeingEdited?: Shift;

  turnOnShiftEditModeEvent: (Shift: Shift) => void;
  turnOffShiftEditModeEvent: () => void;

  showMoreDialog: boolean;
  setShowMoreDialog: (value: boolean) => void;

  showDetailsDialog: boolean;
  setShowDetailsDialog: (value: boolean) => void;

  showCreateShiftModal: boolean;
  setShowCreateShiftModal: (value: boolean) => void;

  showCalendarView: boolean;
  setShowCalendarView: (value: boolean) => void;

  showCalendarTodayView: boolean;
  setShowCalendarTodayView: (value: boolean) => void;

  showAddEmployeeModal: boolean;
  setShowAddEmployeeModal: (value: boolean) => void;

  dayEvents: ShiftMetas[];
  setDayEvents: (value: ShiftMetas[]) => void;

  dayEventDetails: ShiftMetas | undefined;
  setDayEventDetails: (value?: ShiftMetas) => void;
}

export const createShiftSlice: StateCreator<ShiftSlice, [], [], ShiftSlice> = (
  set,
  get
) => ({
  shifts: [],
  failureFetchingShifts: '',
  isFetchingShifts: false,

  failureCreatingShift: '',
  isCreatingShift: false,
  successfullyCreatedShift: false,

  failureUpdatingShift: '',
  isUpdatingShift: false,
  successfullyUpdatedShift: false,

  shiftBeingRemoved: '',
  failureRemovingShift: '',
  isRemovingShift: false,
  successfullyRemovedShift: false,

  isShiftEditMode: false,
  shiftBeingEdited: undefined,

  showMoreDialog: false,
  setShowMoreDialog: (value: boolean) => {
    set({
      showMoreDialog: value,
    });
  },

  showDetailsDialog: false,
  setShowDetailsDialog: (value: boolean) => {
    set({
      showDetailsDialog: value,
    });
  },

  showCreateShiftModal: false,
  setShowCreateShiftModal: (value: boolean) => {
    set({
      showCreateShiftModal: value,
    });
  },

  showCalendarView: true,
  setShowCalendarView: (value: boolean) => {
    set({
      showCalendarView: value,
    });
  },

  showCalendarTodayView: true,
  setShowCalendarTodayView: (value: boolean) => {
    set({
      showCalendarTodayView: value,
    });
  },

  showAddEmployeeModal: false,
  setShowAddEmployeeModal: (value: boolean) => {
    set({
      showAddEmployeeModal: value,
    });
  },

  dayEvents: [],
  setDayEvents: (value: ShiftMetas[]) => {
    set({
      dayEvents: value,
    });
  },

  dayEventDetails: undefined,
  setDayEventDetails: (value?: ShiftMetas) => {
    set({
      dayEventDetails: value,
    });
  },

  resetShiftFailureEvent: () =>
    set({
      failureCreatingShift: '',
      failureFetchingShifts: '',
      failureRemovingShift: '',
      failureUpdatingShift: '',
    }),

  //events
  createShiftEvent: async (details: createShiftDto) => {
    set({ isCreatingShift: true });

    //add employees
    const users = await fetchAllEmployeesForAGivenProjectAndTeamAction(
      details.teamID,
      details.projectID
    );

    if (users !== 'string') {
      details.employees = users as User[];
    }

    //add shiftmetas
    const dates = getDates(
      new Date(details.startDate),
      new Date(details.endDate)
    );

    const shiftMetas = dates.map((date) => {
      const startTime = moment(details.startTime);
      const endTime = moment(details.endTime);
      const dateToday = moment(date.getTime());

      const _startTime = moment()
        .set('year', dateToday.year())
        .set('month', dateToday.month())
        .set('date', dateToday.date())
        .set('hour', startTime.hour())
        .set('minute', startTime.minute());

      const _endTime = moment()
        .set('year', dateToday.year())
        .set('month', dateToday.month())
        .set('date', dateToday.date())
        .set('hour', endTime.hour())
        .set('minute', endTime.minute());

      return {
        id: uuidv4(),
        date: dateToday.valueOf(),
        startTime: _startTime.valueOf(),
        startTimeFormatted: _startTime.format('MMMM Do YYYY, h:mm:ss a'),
        endTime: _endTime.valueOf(),
        endTimeFormatted: _endTime.format('MMMM Do YYYY, h:mm:ss a'),
        mandatory: details.mandatory,
        offDay: false,
        shiftId: details.id,
        weekDays: details.weekDays,
        teamId: details.teamID,
      } as ShiftMetas;
    });

    details.shiftMetas = shiftMetas;

    const shiftTask = details.shiftTasks.map((task) => {
      return {
        ...task,
        shiftId: details.id,
      } as ShiftTasks;
    });

    details.shiftTasks = shiftTask;

    const res = await createShiftAction(details);

    if (typeof res === 'string') {
      set({ isCreatingShift: false, failureCreatingShift: res });
      return;
    }

    set({
      isCreatingShift: false,
      failureCreatingShift: '',
      successfullyCreatedShift: true,
    });

    get().fetchShiftsEvent();

    setTimeout(() => {
      set({
        successfullyCreatedShift: false,
      });
    }, 300);
  },
  updateShiftEvent: async (
    shiftID: string,
    details: createShiftDto | Partial<createShiftDto>
  ) => {
    const detail = details as createShiftDto;

    set({ isUpdatingShift: true });

    //add shiftmetas
    const dates = getDates(
      new Date(detail.startDate),
      new Date(detail.endDate)
    );

    const shiftMetas = dates.map((date) => {
      const startTime = moment(detail.startTime);
      const endTime = moment(detail.endTime);
      const dateToday = moment(date.getTime());

      const _startTime = moment()
        .set('year', dateToday.year())
        .set('month', dateToday.month())
        .set('date', dateToday.date())
        .set('hour', startTime.hour())
        .set('minute', startTime.minute());

      const _endTime = moment()
        .set('year', dateToday.year())
        .set('month', dateToday.month())
        .set('date', dateToday.date())
        .set('hour', endTime.hour())
        .set('minute', endTime.minute());

      return {
        id: uuidv4(),
        date: dateToday.valueOf(),
        startTime: _startTime.valueOf(),
        startTimeFormatted: _startTime.format('MMMM Do YYYY, h:mm:ss a'),
        endTime: _endTime.valueOf(),
        endTimeFormatted: _endTime.format('MMMM Do YYYY, h:mm:ss a'),
        mandatory: detail.mandatory,
        offDay: false,
        shiftId: detail.id,
        weekDays: detail.weekDays,
        teamId: detail.teamID,
      } as ShiftMetas;
    });

    detail.shiftMetas = shiftMetas;

    const res = await updateShiftAction(shiftID, detail);

    if (typeof res === 'string') {
      set({ isUpdatingShift: false, failureUpdatingShift: res });
      return;
    }

    set({
      isUpdatingShift: false,
      failureCreatingShift: '',
      successfullyUpdatedShift: true,
    });

    get().fetchShiftsEvent();

    setTimeout(() => {
      set({
        successfullyUpdatedShift: false,
      });
    }, 300);
  },
  removeShiftEvent: async (shiftID: string) => {
    set({ isRemovingShift: true, shiftBeingRemoved: shiftID });

    const res = await deleteShiftAction(shiftID);

    if (typeof res === 'string') {
      set({
        isRemovingShift: false,
        failureRemovingShift: res,
        shiftBeingRemoved: '',
      });
      return;
    }

    set({
      isRemovingShift: false,
      failureRemovingShift: '',
      successfullyRemovedShift: true,
      shiftBeingRemoved: '',
    });

    get().fetchShiftsEvent();

    setTimeout(() => {
      set({
        successfullyRemovedShift: false,
      });
    }, 300);
  },
  fetchShiftsEvent: async () => {
    set({ isFetchingShifts: true });

    const res = await fetchShiftsAction();

    if (typeof res === 'string') {
      set({ isFetchingShifts: false, failureFetchingShifts: res });
      return;
    }

    set({
      isFetchingShifts: false,
      failureFetchingShifts: '',
      shifts: res,
    });
  },

  turnOnShiftEditModeEvent: (shift: Shift) =>
    set({
      isShiftEditMode: true,
      shiftBeingEdited: shift,
    }),
  turnOffShiftEditModeEvent: () =>
    set({
      isShiftEditMode: false,
      shiftBeingEdited: undefined,
    }),
});
