import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../common/configs/shiftswap-firebase';
import { Role } from '../common/constants/enum';
import { User } from '../models/users/users';
import { handleAuthError, handleError } from '../utils/handle_errors';

export type loginUserDto = {
  email: string;
  password: string;
};

export type createUserDto = {
  email: string;
  password: string;
  role: Role;
  createdAt: number;
  firstName: string;
  id: string;
  isActive: boolean;
  lastName: string;
  phone: string;
  teamId: number | string | undefined;
  updatedAt: number;
};

export type resetPasswordDto = {
  email: string;
};

export type setNewPasswordUserDto = {
  email: string;
  password: string;
  resetKey: String;
};

const updateUserAction = async (
  userID: string,
  details: Partial<User>
): Promise<User | string> => {
  try {
    await setDoc(doc(db, 'users', userID), details, {
      merge: true,
    });

    return await getUserAction(userID);
  } catch (error: any) {
    return handleAuthError(error['code']);
  }
};

const getUserAction = async (userID: string): Promise<User | string> => {
  try {
    const docu = await getDoc(doc(db, 'users', userID));

    return docu.data() as User;
  } catch (error: any) {
    return handleAuthError(error['code']);
  }
};

const loginUserAction = async (
  details: loginUserDto
): Promise<User | string> => {
  try {
    const credentials: UserCredential = await signInWithEmailAndPassword(
      auth,
      details.email,
      details.password
    );

    const user = credentials.user;

    const docRef = doc(db, 'users', user.uid);

    const docSnap = await getDoc(docRef);

    return docSnap.data() as User;
  } catch (error: any) {
    return handleAuthError(error['code']);
  }
};

const createUserAction = async (
  details: createUserDto
): Promise<User | string> => {
  try {
    const credentials: UserCredential = await createUserWithEmailAndPassword(
      auth,
      details.email,
      details.password
    );

    const user = credentials.user;

    const additionaInfo = getAdditionalUserInfo(credentials);

    if (additionaInfo?.isNewUser) {
      details.id = user.uid;
      details.password = '';
      await setDoc(doc(db, 'users', details.id), details);
    }

    const docRef = doc(db, 'users', user.uid);

    const docSnap = await getDoc(docRef);

    return docSnap.data() as User;
  } catch (error: any) {
    console.log(error);
    return handleAuthError(error['code']);
  }
};

const resetPasswordAction = async (
  details: resetPasswordDto
): Promise<
  | {
      message: string;
    }
  | string
> => {
  try {
    await sendPasswordResetEmail(auth, details.email);
    return {
      message: 'We have sent a reset password link to your email.',
    } as {
      message: string;
    };
  } catch (error) {
    return handleError(error);
  }
};

const logoutAction = async (): Promise<
  | {
      message: string;
    }
  | string
> => {
  try {
    await signOut(auth);
    return {
      message: 'successfully logged out',
    } as {
      message: string;
    };
  } catch (error) {
    return handleError(error);
  }
};

export {
  loginUserAction,
  resetPasswordAction,
  createUserAction,
  logoutAction,
  getUserAction,
  updateUserAction,
};
