/* eslint-disable no-unused-vars */
import { StateCreator } from 'zustand';
import { FilterEmployeeListByStatus } from '../../common/constants/enum';
import { User } from '../../models/users/users';
import {
  activateEmployeeAction,
  addNewEmployeeAction,
  addNewEmployeeDto,
  deactivateEmployeeAction,
  fetchActivatedEmployeesAction,
  fetchAllEmployeesAction,
  fetchAllEmployeesForAGivenProjectAndTeamAction,
  fetchAllEmployeesUnderAProjectAction,
  fetchAllEmployeesUnderATeamAction,
  fetchDeactivatedEmployeesAction,
} from '../../repositories/employee_repo';

export interface EmployeeSlice {
  employees: User[];

  //create a new empolyee
  addNewEmployeeEvent: (details: addNewEmployeeDto) => void;
  failureAddingNewEmployee: string;
  isAddingNewEmployee: boolean;
  successfullyAddedNewEmployee: boolean;

  //fetch employees
  fetchEmployeesEvent: () => void;
  failureFetchingEmployees: string;
  isFetchingEmployees: boolean;

  //activate employees
  activateEmployeeEvent: (userID: string) => void;
  failureActivatingEmployee: string;
  isActivatingEmployee: boolean;

  //deactivate employees
  deactivateEmployeeEvent: (userID: string) => void;
  failureDeactivatingEmployee: string;
  isDeactivatingEmployee: boolean;

  setFilterEmployeeListByStatus: (status: FilterEmployeeListByStatus) => void;
  filterEmployeeListByStatus: FilterEmployeeListByStatus;

  setFilterEmployeeListByProject: (projectID: string | undefined) => void;
  filterEmployeeListByProject: string | undefined;

  setFilterEmployeeListByTeam: (teamID: string | undefined) => void;
  filterEmployeeListByTeam: string | undefined;

  resetEmployeeFailureEvent: () => void;
}

export const createEmployeeSlice: StateCreator<
  EmployeeSlice,
  [],
  [],
  EmployeeSlice
> = (set, get) => ({
  employees: [],
  failureFetchingEmployees: '',
  isFetchingEmployees: false,

  filterEmployeeListByStatus: FilterEmployeeListByStatus.All,
  filterEmployeeListByTeam: undefined,
  filterEmployeeListByProject: undefined,

  failureActivatingEmployee: '',
  isActivatingEmployee: false,

  failureDeactivatingEmployee: '',
  isDeactivatingEmployee: false,

  failureAddingNewEmployee: '',
  isAddingNewEmployee: false,
  successfullyAddedNewEmployee: false,

  addNewEmployeeEvent: async (details: addNewEmployeeDto) => {
    set({ isAddingNewEmployee: true });

    const res = await addNewEmployeeAction(details);

    if (typeof res === 'string') {
      set({ isAddingNewEmployee: false, failureAddingNewEmployee: res });
      return;
    }

    set({
      isAddingNewEmployee: false,
      failureAddingNewEmployee: '',
      successfullyAddedNewEmployee: true,
    });

    get().fetchEmployeesEvent();

    setTimeout(() => {
      set({
        successfullyAddedNewEmployee: false,
      });
    }, 300);
  },

  deactivateEmployeeEvent: async (userID: string) => {
    set({ isDeactivatingEmployee: true });

    const res = await deactivateEmployeeAction(userID);

    if (typeof res === 'string') {
      set({ isDeactivatingEmployee: false, failureDeactivatingEmployee: res });
      return;
    }

    set({
      isDeactivatingEmployee: false,
      failureDeactivatingEmployee: '',
    });

    get().fetchEmployeesEvent();
  },

  activateEmployeeEvent: async (userID: string) => {
    set({ isActivatingEmployee: true });

    const res = await activateEmployeeAction(userID);

    if (typeof res === 'string') {
      set({ isActivatingEmployee: false, failureActivatingEmployee: res });
      return;
    }

    set({
      isActivatingEmployee: false,
      failureActivatingEmployee: '',
    });

    get().fetchEmployeesEvent();
  },

  setFilterEmployeeListByProject: (projectID: string | undefined) => {
    set({ filterEmployeeListByProject: projectID });
  },

  setFilterEmployeeListByTeam: (teamID: string | undefined) => {
    set({ filterEmployeeListByTeam: teamID });
  },

  setFilterEmployeeListByStatus: (status: FilterEmployeeListByStatus) => {
    set({ filterEmployeeListByStatus: status });
  },

  resetEmployeeFailureEvent: () =>
    set({
      failureActivatingEmployee: '',
      failureAddingNewEmployee: '',
      failureDeactivatingEmployee: '',
      failureFetchingEmployees: '',
    }),

  fetchEmployeesEvent: async () => {
    set({ isFetchingEmployees: true });

    const filterEmployeeListByProject = get().filterEmployeeListByProject;
    const filterEmployeeListByStatus = get().filterEmployeeListByStatus;
    const filterEmployeeListByTeam = get().filterEmployeeListByTeam;

    let res: User[] | string = '';

    if (
      filterEmployeeListByProject === undefined &&
      filterEmployeeListByTeam === undefined
    ) {
      if (filterEmployeeListByStatus === FilterEmployeeListByStatus.Activated) {
        res = await fetchActivatedEmployeesAction();
      }

      if (
        filterEmployeeListByStatus === FilterEmployeeListByStatus.Deactivated
      ) {
        res = await fetchDeactivatedEmployeesAction();
      }

      if (filterEmployeeListByStatus === FilterEmployeeListByStatus.All) {
        res = await fetchAllEmployeesAction();
      }
    }

    if (
      filterEmployeeListByProject !== undefined &&
      filterEmployeeListByTeam !== undefined
    ) {
      res = await fetchAllEmployeesForAGivenProjectAndTeamAction(
        filterEmployeeListByTeam,
        filterEmployeeListByProject
      );
    }
    if (
      filterEmployeeListByProject !== undefined &&
      filterEmployeeListByTeam === undefined
    ) {
      res = await fetchAllEmployeesUnderAProjectAction(
        filterEmployeeListByProject
      );
    }

    if (
      filterEmployeeListByProject === undefined &&
      filterEmployeeListByTeam !== undefined
    ) {
      res = await fetchAllEmployeesUnderATeamAction(filterEmployeeListByTeam);
    }

    if (typeof res === 'string') {
      set({ isFetchingEmployees: false, failureFetchingEmployees: res });
      return;
    }

    if (
      (filterEmployeeListByStatus !== FilterEmployeeListByStatus.All &&
        filterEmployeeListByProject !== undefined) ||
      filterEmployeeListByTeam !== undefined
    ) {
      if (filterEmployeeListByStatus === FilterEmployeeListByStatus.Activated) {
        res = (res as User[]).filter((u: User) => u.isActive);
      }

      if (
        filterEmployeeListByStatus === FilterEmployeeListByStatus.Deactivated
      ) {
        res = (res as User[]).filter((u: User) => !u.isActive);
      }
    }

    console.log('res', res);
    set({
      isFetchingEmployees: false,
      failureFetchingEmployees: '',
      employees: res,
    });
  },
});
