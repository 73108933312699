/* eslint-disable no-unused-vars */
import { StateCreator } from 'zustand';
import { MessageAccessLevel } from '../../common/constants/enum';
import { Message } from '../../models/messages/message';
import {
  createMessageAction,
  createMessageDto,
  deleteMessageAction,
  fetchMessagesAction,
  updateMessageAction,
} from '../../repositories/messages_repo';

export interface MessageSlice {
  messages: Message[];

  //crud
  createMessageEvent: (details: createMessageDto) => void;
  failureCreatingMessage: string;
  isCreatingMessage: boolean;
  successfullyCreatedMessage: boolean;

  updateMessageEvent: (
    taskID: string,
    subject: string,
    message: string,
    accessLevel: MessageAccessLevel,
    projectID?: string,
    teamID?: string
  ) => void;
  failureUpdatingMessage: string;
  isUpdatingMessage: boolean;
  successfullyUpdatedMessage: boolean;

  removeMessageEvent: (messageID: string) => void;
  messageBeingRemoved: string;
  failureRemovingMessage: string;
  isRemovingMessage: boolean;
  successfullyRemovedMessage: boolean;

  //fetch teams
  fetchMessagesEvent: () => void;
  failureFetchingMessages: string;
  isFetchingMessages: boolean;

  //resetTeam
  resetMessageFailureEvent: () => void;

  //
  isMessageEditMode: boolean;
  messageBeingEdited?: Message;

  turnOnMessageEditModeEvent: (message: Message) => void;
  turnOffMessageEditModeEvent: () => void;
}

export const createMessageSlice: StateCreator<
  MessageSlice,
  [],
  [],
  MessageSlice
> = (set, get) => ({
  messages: [],
  failureFetchingMessages: '',
  isFetchingMessages: false,

  failureCreatingMessage: '',
  isCreatingMessage: false,
  successfullyCreatedMessage: false,

  failureUpdatingMessage: '',
  isUpdatingMessage: false,
  successfullyUpdatedMessage: false,

  messageBeingRemoved: '',
  failureRemovingMessage: '',
  isRemovingMessage: false,
  successfullyRemovedMessage: false,

  isMessageEditMode: false,
  messageBeingEdited: undefined,

  resetMessageFailureEvent: () =>
    set({
      failureCreatingMessage: '',
      failureFetchingMessages: '',
      failureRemovingMessage: '',
      failureUpdatingMessage: '',
    }),

  //events
  createMessageEvent: async (details: createMessageDto) => {
    set({ isCreatingMessage: true });

    const res = await createMessageAction(details);

    if (typeof res === 'string') {
      set({ isCreatingMessage: false, failureCreatingMessage: res });
      return;
    }

    set({
      isCreatingMessage: false,
      failureCreatingMessage: '',
      successfullyCreatedMessage: true,
    });

    get().fetchMessagesEvent();

    setTimeout(() => {
      set({
        successfullyCreatedMessage: false,
      });
    }, 300);
  },
  updateMessageEvent: async (
    messageID: string,
    subject: string,
    message: string,
    accessLevel: MessageAccessLevel,
    projectID?: string,
    teamID?: string
  ) => {
    set({ isUpdatingMessage: true });

    const res = await updateMessageAction(
      messageID,
      subject,
      message,
      accessLevel,
      projectID,
      teamID
    );

    if (typeof res === 'string') {
      set({ isUpdatingMessage: false, failureUpdatingMessage: res });
      return;
    }

    set({
      isUpdatingMessage: false,
      failureUpdatingMessage: '',
      successfullyUpdatedMessage: true,
    });

    get().fetchMessagesEvent();

    setTimeout(() => {
      set({
        successfullyUpdatedMessage: false,
      });
    }, 300);
  },
  removeMessageEvent: async (messageID: string) => {
    set({ isRemovingMessage: true, messageBeingRemoved: messageID });

    const res = await deleteMessageAction(messageID);

    if (typeof res === 'string') {
      set({
        isRemovingMessage: false,
        failureRemovingMessage: res,
        messageBeingRemoved: '',
      });
      return;
    }

    get().fetchMessagesEvent();

    set({
      isRemovingMessage: false,
      failureRemovingMessage: '',
      successfullyRemovedMessage: true,
      messageBeingRemoved: '',
    });

    setTimeout(() => {
      set({
        successfullyRemovedMessage: false,
      });
    }, 300);
  },
  fetchMessagesEvent: async () => {
    set({ isFetchingMessages: true });

    const res = await fetchMessagesAction();

    if (typeof res === 'string') {
      set({ isFetchingMessages: false, failureFetchingMessages: res });
      return;
    }

    set({
      isFetchingMessages: false,
      failureFetchingMessages: '',
      messages: res,
    });
  },
  turnOnMessageEditModeEvent: (message: Message) =>
    set({
      isMessageEditMode: true,
      messageBeingEdited: message,
    }),
  turnOffMessageEditModeEvent: () =>
    set({
      isMessageEditMode: false,
      messageBeingEdited: undefined,
    }),
});
