/* eslint-disable no-unused-vars */
import { StateCreator } from 'zustand';
import { Task } from '../../models/task/task';
import {
  createTaskAction,
  createTaskDto,
  fetchTaskAction,
  removeTaskAction,
  updateTaskAction,
} from '../../repositories/task_repo';

export interface TaskSlice {
  tasks: Task[];

  //crud
  createTaskEvent: (details: createTaskDto) => void;
  failureCreatingTask: string;
  isCreatingTask: boolean;
  successfullyCreatedTask: boolean;

  updateTaskEvent: (taskID: string, details: Partial<createTaskDto>) => void;
  failureUpdatingTask: string;
  isUpdatingTask: boolean;
  successfullyUpdatedTask: boolean;

  removeTaskEvent: (taskID: string) => void;
  taskBeingRemoved: string;
  failureRemovingTask: string;
  isRemovingTask: boolean;
  successfullyRemovedTask: boolean;

  //fetch teams
  fetchTasksEvent: () => void;
  failureFetchingTasks: string;
  isFetchingTasks: boolean;

  //resetTeam
  resetTaskFailureEvent: () => void;

  //
  isTaskEditMode: boolean;
  taskBeingEdited?: Task;

  turnOnTaskEditModeEvent: (task: Task) => void;
  turnOffTaskEditModeEvent: () => void;
}

export const createTaskSlice: StateCreator<TaskSlice, [], [], TaskSlice> = (
  set,
  get
) => ({
  tasks: [],
  failureFetchingTasks: '',
  isFetchingTasks: false,

  failureCreatingTask: '',
  isCreatingTask: false,
  successfullyCreatedTask: false,

  failureUpdatingTask: '',
  isUpdatingTask: false,
  successfullyUpdatedTask: false,

  taskBeingRemoved: '',
  failureRemovingTask: '',
  isRemovingTask: false,
  successfullyRemovedTask: false,

  isTaskEditMode: false,
  taskBeingEdited: undefined,

  resetTaskFailureEvent: () =>
    set({
      failureCreatingTask: '',
      failureFetchingTasks: '',
      failureRemovingTask: '',
      failureUpdatingTask: '',
    }),

  //events
  createTaskEvent: async (details: createTaskDto) => {
    set({ isCreatingTask: true });

    const res = await createTaskAction(details);

    if (typeof res === 'string') {
      set({ isCreatingTask: false, failureCreatingTask: res });
      return;
    }

    set({
      isCreatingTask: false,
      failureCreatingTask: '',
      successfullyCreatedTask: true,
    });

    get().fetchTasksEvent();

    setTimeout(() => {
      set({
        successfullyCreatedTask: false,
      });
    }, 300);
  },
  updateTaskEvent: async (teamID: string, details: Partial<createTaskDto>) => {
    set({ isUpdatingTask: true });

    const res = await updateTaskAction(teamID, details);

    if (typeof res === 'string') {
      set({ isUpdatingTask: false, failureUpdatingTask: res });
      return;
    }

    set({
      isUpdatingTask: false,
      failureUpdatingTask: '',
      successfullyUpdatedTask: true,
    });

    get().fetchTasksEvent();

    setTimeout(() => {
      set({
        successfullyUpdatedTask: false,
      });
    }, 300);
  },
  removeTaskEvent: async (teamID: string) => {
    set({ isRemovingTask: true, taskBeingRemoved: teamID });

    const res = await removeTaskAction(teamID);

    if (typeof res === 'string') {
      set({
        isRemovingTask: false,
        failureRemovingTask: res,
        taskBeingRemoved: '',
      });
      return;
    }

    set({
      isRemovingTask: false,
      failureRemovingTask: '',
      successfullyRemovedTask: true,
      taskBeingRemoved: '',
    });

    get().fetchTasksEvent();

    setTimeout(() => {
      set({
        successfullyRemovedTask: false,
      });
    }, 300);
  },
  fetchTasksEvent: async () => {
    set({ isFetchingTasks: true });

    const res = await fetchTaskAction();

    if (typeof res === 'string') {
      set({ isFetchingTasks: false, failureFetchingTasks: res });
      return;
    }

    set({
      isFetchingTasks: false,
      failureFetchingTasks: '',
      tasks: res,
    });
  },
  turnOnTaskEditModeEvent: (task: Task) =>
    set({
      isTaskEditMode: true,
      taskBeingEdited: task,
    }),
  turnOffTaskEditModeEvent: () =>
    set({
      isTaskEditMode: false,
      taskBeingEdited: undefined,
    }),
});
