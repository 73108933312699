import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { AuthSlice, createAuthSlice } from './slices/auth';
import { createEmployeeSlice, EmployeeSlice } from './slices/employee';
import { createMessageSlice, MessageSlice } from './slices/message';
import { createProjectSlice, ProjectSlice } from './slices/project';
import { createShiftSlice, ShiftSlice } from './slices/shift';
import { createTaskSlice, TaskSlice } from './slices/task';
import { createTeamSlice, TeamsSlice } from './slices/teams';

export const useBoundStore = create<
  AuthSlice &
    ProjectSlice &
    TeamsSlice &
    TaskSlice &
    EmployeeSlice &
    MessageSlice &
    ShiftSlice,
  [['zustand/persist', AuthSlice], ['zustand/devtools', never]]
>(
  persist(
    devtools((...a) => ({
      ...createAuthSlice(a[0], a[1], a[2], []),
      ...createProjectSlice(a[0], a[1], a[2], []),
      ...createTeamSlice(a[0], a[1], a[2], []),
      ...createTaskSlice(a[0], a[1], a[2], []),
      ...createEmployeeSlice(a[0], a[1], a[2], []),
      ...createMessageSlice(a[0], a[1], a[2], []),
      ...createShiftSlice(a[0], a[1], a[2], []),
    })),
    {
      name: 'shiftswap_storage',
      partialize: (state) => {
        state.isSubmittingLogin = false;
        state.isSubmittingSendEmailReset = false;
        state.isSubmittingSetNewPassword = false;

        return state;
      },
    }
  )
);
