// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBmQxtdzhE4xoTf2gOT9uHm9DuuQHQRMdg',
  authDomain: 'shiftswap-d3597.firebaseapp.com',
  projectId: 'shiftswap-d3597',
  storageBucket: 'shiftswap-d3597.appspot.com',
  messagingSenderId: '5415134855',
  appId: '1:5415134855:web:3b5d54b2037412bb403a22',
  measurementId: 'G-MVJNNXP0ZX',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
