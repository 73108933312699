/* eslint-disable no-unused-vars */
import { StateCreator } from 'zustand';
import { Project } from '../../models/projects/project';
import {
  createProjectAction,
  createProjectDto,
  fetchProjectsAction,
  removeProjectAction,
  updateProjectAction,
} from '../../repositories/project_repo';

export interface ProjectSlice {
  projects: Project[];

  //crud
  createProjectEvent: (details: createProjectDto) => void;
  failureCreatingProjects: string;
  isCreatingProjects: boolean;
  successfullyCreatedProject: boolean;

  updateProjectEvent: (
    projectID: string,
    details: Partial<createProjectDto>
  ) => void;
  failureUpdatingProjects: string;
  isUpdatingProjects: boolean;
  successfullyUpdatedProject: boolean;

  removeProjectEvent: (projectID: string) => void;
  projectBeingRemoved: string;
  failureRemovingProjects: string;
  isRemovingProjects: boolean;
  successfullyRemovedProject: boolean;

  //fetch projects
  fetchProjectsEvent: () => void;
  failureFetchingProjects: string;
  isFetchingProjects: boolean;

  //resetProject
  resetProjectFailureEvent: () => void;

  //
  isEditMode: boolean;
  projectBeingEdited?: Project;

  turnOnEditModeEvent: (project: Project) => void;
  turnOffEditModeEvent: () => void;
}

export const createProjectSlice: StateCreator<
  ProjectSlice,
  [],
  [],
  ProjectSlice
> = (set, get) => ({
  projects: [],
  failureFetchingProjects: '',
  isFetchingProjects: false,

  failureCreatingProjects: '',
  isCreatingProjects: false,
  successfullyCreatedProject: false,

  failureUpdatingProjects: '',
  isUpdatingProjects: false,
  successfullyUpdatedProject: false,

  projectBeingRemoved: '',
  failureRemovingProjects: '',
  isRemovingProjects: false,
  successfullyRemovedProject: false,

  isEditMode: false,
  projectBeingEdited: undefined,

  resetProjectFailureEvent: () =>
    set({
      failureCreatingProjects: '',
      failureFetchingProjects: '',
      failureRemovingProjects: '',
      failureUpdatingProjects: '',
    }),

  //events
  createProjectEvent: async (details: createProjectDto) => {
    set({ isCreatingProjects: true });

    const res = await createProjectAction(details);

    if (typeof res === 'string') {
      set({ isCreatingProjects: false, failureCreatingProjects: res });
      return;
    }

    set({
      isCreatingProjects: false,
      failureCreatingProjects: '',
      successfullyCreatedProject: true,
    });

    get().fetchProjectsEvent();

    setTimeout(() => {
      set({
        successfullyCreatedProject: false,
      });
    }, 300);
  },
  updateProjectEvent: async (
    projectID: string,
    details: Partial<createProjectDto>
  ) => {
    set({ isUpdatingProjects: true });

    const res = await updateProjectAction(projectID, details);

    if (typeof res === 'string') {
      set({ isUpdatingProjects: false, failureUpdatingProjects: res });
      return;
    }

    set({
      isUpdatingProjects: false,
      failureUpdatingProjects: '',
      successfullyUpdatedProject: true,
    });

    get().fetchProjectsEvent();

    setTimeout(() => {
      set({
        successfullyUpdatedProject: false,
      });
    }, 300);
  },
  removeProjectEvent: async (projectID: string) => {
    set({ isRemovingProjects: true, projectBeingRemoved: projectID });

    const res = await removeProjectAction(projectID);

    if (typeof res === 'string') {
      set({
        isRemovingProjects: false,
        failureRemovingProjects: res,
        projectBeingRemoved: '',
      });
      return;
    }

    set({
      isRemovingProjects: false,
      failureRemovingProjects: '',
      successfullyRemovedProject: true,
      projectBeingRemoved: '',
    });

    get().fetchProjectsEvent();

    setTimeout(() => {
      set({
        successfullyRemovedProject: false,
      });
    }, 300);
  },
  fetchProjectsEvent: async () => {
    set({ isFetchingProjects: true });

    const res = await fetchProjectsAction();

    if (typeof res === 'string') {
      set({ isFetchingProjects: false, failureFetchingProjects: res });
      return;
    }

    set({
      isFetchingProjects: false,
      failureFetchingProjects: '',
      projects: res,
    });
  },
  turnOnEditModeEvent: (project: Project) =>
    set({
      isEditMode: true,
      projectBeingEdited: project,
    }),
  turnOffEditModeEvent: () =>
    set({
      isEditMode: false,
      projectBeingEdited: undefined,
    }),
});
