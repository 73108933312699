import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { db } from '../common/configs/shiftswap-firebase';
import { Task } from '../models/task/task';
import { handleError } from '../utils/handle_errors';

export type createTaskDto = {
  id: string;
  createdAt: number;
  title: string;
  description: string;
};

const fetchTaskAction = async (): Promise<Task[] | string> => {
  try {
    const tasksCol = collection(db, 'tasks');

    const snapshots = await getDocs(
      query(tasksCol, orderBy('createdAt', 'desc'))
    );

    const tasks = snapshots.docs.map((task) => task.data() as Task);

    return tasks;
  } catch (error) {
    return handleError(error);
  }
};

const createTaskAction = async (
  details: createTaskDto
): Promise<Task | string> => {
  try {
    await setDoc(doc(db, 'tasks', details.id), details);

    return details as Task;
  } catch (error) {
    return handleError(error);
  }
};

const removeTaskAction = async (details: string): Promise<Task | string> => {
  try {
    await deleteDoc(doc(db, 'tasks', details));

    return {} as Task;
  } catch (error) {
    return handleError(error);
  }
};

const updateTaskAction = async (
  taskId: string,
  details: Partial<createTaskDto>
): Promise<Task | string> => {
  try {
    await setDoc(doc(db, 'tasks', taskId), details, {
      merge: true,
    });

    return details as Task;
  } catch (error) {
    return handleError(error);
  }
};

export {
  fetchTaskAction,
  createTaskAction,
  removeTaskAction,
  updateTaskAction,
};
